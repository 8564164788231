import Strategy = require("../model/strategy");
import Portfolio = require("../model/portfolio");

class Client {

    public fetchStrategy(strategy: Strategy, idToken: string): Promise<Response> {
        return fetch("https://api.bors.switchca.se/v1/strategy/"+strategy.name, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: idToken,
                }
            }
        );
    }

    public fetchHoldings(portfolio: Portfolio, idToken: string): Promise<Response> {
        return fetch("https://api.bors.switchca.se/v1/portfolio/"+portfolio.name, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: idToken,
                }
            }
        );
    }
}

export = Client;
