import * as React from "react";
import * as ReactDOM from "react-dom";
import Amplify from "@aws-amplify/core";
import {Layout} from "./components/layout";
import pageStore = require("./store/pagestore");
import userStore = require("./store/userstore");

let content: any;

export function render() {
    window.onerror = (event: Event | string, source?: string, fileno?: number, columnNumber?: number, error?: Error): void => {
        console.error(event, fileno + ":" + columnNumber, error);
    };

    clearStores();

    content = ReactDOM.render(<Layout />, document.getElementById("root"));

    window.onchange = () => {
        content.forceUpdate();
    };

    Amplify.configure(createConfiguration());
}

function createConfiguration() {
    return {
        Auth: {
            region: "eu-west-1",
            userPoolId: 'eu-west-1_2MqF5rTX2',
            userPoolWebClientId: '2t99c07qvur52c5ieues34forc',
            authenticationFlowType: 'USER_SRP_AUTH',
            mandatorySignIn: true
        }
    }
}

function clearStores() {
    [
        pageStore,
        userStore
    ].forEach(store => store.clear());
}
