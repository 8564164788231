import * as React from "react";
import { Fragment } from "react";
import Login from "./login";
import {Link, Redirect, Route, Router, Switch} from "react-router-dom";
import Stocks from "./private/stocks";
import history from "../history/history";
import userStore = require("../store/userstore");
import Menu from "./menu";
import About from "./about";

export class Layout extends React.Component<{}, {}> {

    constructor(props: {}, context?: any) {
        super(props, context);
        this.onStateChange = this.onStateChange.bind(this);
    }

    private onStateChange() {
        console.log("State changed in layout component")
        this.setState({});
    }

    public componentDidMount(): void {
        userStore.onChange(user => {
           // console.log("User was updated with: " + user.username)
            this.setState({});
        });
    }

    public render() {
        console.log("Rendering layout");
        return (
            <div className="mainBody">
                <Router history={history}>
                        <Menu/>
                        <Fragment>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/login" />;
                                </Route>
                                <Route path="/stocks">
                                    <Stocks />
                                </Route>
                                <Route path="/about">
                                    <About />
                                </Route>
                                <Route path="/login">
                                    <Login />
                                </Route>
                                <Route path="*" component={(el:any) => (
                                    <Fragment>
                                        <div className="body">
                                            <p className="center-text">Inget hittades på {el.match.url}</p>
                                            <p>Gå tillbaka till <Link to="/"> startsidan</Link></p>
                                        </div>
                                    </Fragment>
                                )}
                                />
                            </Switch>
                        </Fragment>
                </Router>
            </div>
        );
    }
}
