
export const SWEDEN = "Sverige";
export const NORWAY = "Norge";
export const FINLAND = "Finland";
export const DENMARK = "Danmark";

export function convertCountry(country: number): string {
    switch (country) {
        case 1:
            return SWEDEN;
        case 2:
            return NORWAY;
        case 3:
            return FINLAND;
        case 4:
            return DENMARK;
        default:
            return "Unknown";
    }
}
