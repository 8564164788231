import User = require("../model/user");
import userStore = require("../store/userstore");

export class UserManager {

    public static isAuthenticated(): boolean {
        const userFromStore = userStore.get();
        if (userFromStore !== null) {
            return new Date().getTime() - 1000 * 60 * 60 < userFromStore.sessionStarted
        } else {
            const storedUser = window.localStorage.getItem("USER");
            const user = JSON.parse(storedUser) as User;
            if (user !== null) {
                return new Date().getTime() - 1000 * 60 * 60 < user.sessionStarted
            }
            return false;
        }
    }

    public static get(): User {
        const userFromStore = userStore.get();
        if (userFromStore !== null) {
            return userFromStore;
        } else {
            const storedUser = window.localStorage.getItem("USER");
            return JSON.parse(storedUser) as User;
        }
    }

    public static clearUser() {
        window.localStorage.setItem("USER", null);
        userStore.clear(true);
    }
}
