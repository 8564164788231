import * as React from "react";
import Client = require("../../client/client");
import User = require("../../model/user");
import {convertCountry} from "../../model/country";
import {round} from "../../util/math/mathutil";

interface MomentumProps {
    user: User
}

interface MomentumState {
    statusCode: number
    jsonResponse: any
    loading: boolean
}

export class MomentumTable extends React.Component<MomentumProps, MomentumState> {

    private client: Client = null;

    constructor(props: MomentumProps, context: any) {
        super(props, context);
        this.client = new Client();
        this.state = {loading: true, statusCode: 0, jsonResponse: null}
    }

    public componentDidMount(): void {
        this.client.fetchStrategy({name: "momentum"}, this.props.user.idToken)
            .then(rsp => {
                if (rsp.status === 200) {
                    rsp.json().then(json => {
                        this.setState({statusCode: rsp.status, jsonResponse: json, loading: false});
                    });
                } else if (rsp.status === 204) {
                    this.setState({statusCode: rsp.status, jsonResponse: null, loading: false});
                }
            });
    }

    private sortOnPeriod(filterInfo: FilterInfo[]): FilterInfo[] {
        filterInfo.sort((a, b) => {
            return a.periodValue > b.periodValue ? 1 : b.periodValue > a.periodValue? -1 : 0;
        });
        return filterInfo;
    }

    public render() {
        if (this.state.loading) {
            return (<img src="../img/loading.gif" className="loading-img" style={{width: "10%"}} />);
        }

        let trs: JSX.Element[] = [];

        if (this.state.statusCode === 200) {

            let momentumModel = this.state.jsonResponse;
            console.log("MomentumModel: ", momentumModel);
            let tr: JSX.Element;
            let {stocks, createDate}: StockRecommendationModel = momentumModel;
            if (stocks) {
                stocks.forEach(stock => {
                    let fi = this.sortOnPeriod(stock.filterInfo);
                    tr = (
                        <tr key={stock.name}>
                            <td>{stock.name}</td>
                            <td>{convertCountry(stock.country)}</td>
                            <td>{round(fi[0].value, 2)}</td>
                            <td>{round(fi[1].value, 2)}</td>
                            <td>{round(fi[2].value, 2)}</td>
                            <td>{round(fi[3].value, 2)}</td>
                            <td>{round(fi[4].value, 2)}</td>
                            <td>{round(fi[5].value, 2)}</td>
                            <td>{round(fi[6].value, 2)}</td>
                        </tr>
                    );
                    trs.push(tr);
                });
            }
        }

        return (
            <div>
                <h2 className="stocks-page-headline">Momentum-strategi</h2>
                <table className="stock-table">
                    <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Land</th>
                        <th>Tillväxt eget kapital</th>
                        <th>Kursutveckling 1 dag</th>
                        <th>Kursutveckling 1 vecka</th>
                        <th>Kursutveckling 1 månad</th>
                        <th>Kursutveckling 3 månader</th>
                        <th>Kursutveckling 6 månader</th>
                        <th>Kursutveckling 1 år</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>
        )
    }
}
