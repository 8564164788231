import * as React from "react";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {MomentumTable} from "./momentum";
import {GrowthTable} from "./growth";
import {UserManager} from "../../managers/usermanager";
import {HoldingsTable} from "./holdings/kapitalforsakring";

export default function Stocks() {
    const history = useHistory();
    const location = useLocation();
    // define state


    if (!UserManager.isAuthenticated()) {
        return <Redirect to={{
            pathname: "/",
            state: { from: location, isAuthenticated: false }
        }} />
    }

    const user = UserManager.get();

    return (
        <div>
            <div className="stocks-page-description">
                <h2>Aktielistor</h2>
                <p>Samtliga listor uppdateras kl 08:30 varje vardag.</p>
            </div>
            <div className="stock-tables">
                <MomentumTable user={user} />
            </div>
            <div className="stock-tables">
                <GrowthTable user={user} />
            </div>

            <div className="stock-tables">
                <HoldingsTable user={user} portfolio={{name: "kapitalforsakring"}} />
            </div>
        </div>
    );
}
