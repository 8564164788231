import * as React from "react";
import { useLocation ,} from "react-router";
import { useHistory, Redirect } from "react-router-dom";
import { useState, useEffect, KeyboardEvent, MouseEvent } from "react";
import User = require("../model/user");
import CognitoException = require("../exceptions/cognitoexception");
import userStore = require("../store/userstore");
import * as H from "history";
import Authenticator = require("../auth/authenticator");
import {UserManager} from "../managers/usermanager";

export default function Login() {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();

    // @ts-ignore
    const { from } = location.state || { from: { pathname: "/" } };

    if (isAuthenticated || UserManager.isAuthenticated()) {

        return (
            <Redirect to={{
                pathname: "/stocks",
                state: { from: location, isAuthenticated: true }
            }} />
        )
    }

    let loginStatus: JSX.Element;
    let passwordField: JSX.Element;
    let loginButton: JSX.Element;

    if (loading) {
        loginButton = <input type="submit" name="Login" id="go" value="Loggar in..."  />
        passwordField = <input type="password" name="password" id="password" placeholder="Lösenord"  />

    } else {
        passwordField = <input type="password" name="password" id="password" placeholder="Lösenord" onKeyPress={(e) => loginByPress({e, setAuthenticated, setLoginFailed, setLoading, history, from})}  />
        loginButton = <input type="submit" name="Login" id="go" value="Logga in" onClick={(e) => onLogin({e, setAuthenticated, setLoginFailed, setLoading, history, from})} />
        loginStatus = <p className="p-container">{showLoginFailed(loginFailed)}</p>
    }

    return (
        <div>
            <div className="background-wrap">
                <div className="background"></div>
            </div>
            <div id="accesspanel" className="form">
                <h1 id="litheader">Switch Case Börs</h1>
                <div className="inset">
                    <p>
                        <input type="text" name="user" id="email" placeholder="Användarnamn" />
                    </p>
                    <p>
                        {passwordField}
                    </p>
                    <input className="loginLoginValue" type="hidden" name="service" value="login"/>
                </div>
                <p className="p-container">
                    {loginButton}
                </p>
                <div>
                    {loginStatus}
                </div>
            </div>
        </div>
    );
}

const authenticator = new Authenticator();

function showLoginFailed(b: boolean): JSX.Element {
    if (b) {
        return (
            <b>Inloggningen misslyckades!</b>
        );
    } else {
        return null;
    }
}

function loginByPress({
                          e,
                          setAuthenticated,
                          setLoginFailed,
                          setLoading,
                          history,
                          from,
                      }: {
    e?: KeyboardEvent<HTMLInputElement>;
    setAuthenticated: (b: boolean) => void;
    setLoginFailed: (b: boolean) => void;
    setLoading: (b: boolean) => void;
    history: H.History;
    from: H.Location;
}) {
    if (e.charCode === 13) {
        onLogin({setAuthenticated, setLoginFailed, setLoading, history, from});
    }
}

function disableInputFields(disable: boolean) {
    const loginDiv = document.querySelector("div.accesspanel");
    if (loginDiv) {
        const inputFields = loginDiv.children;
        for (let i = 0; i < inputFields.length; i++) {
            (inputFields[i] as HTMLInputElement).disabled = disable;
        }
    }
}

function onLogin({
                     e,
                     setAuthenticated,
                     setLoginFailed,
                     setLoading,
                     history,
                     from,
                 }: {
    e?: MouseEvent<HTMLInputElement>;
    setAuthenticated: (b: boolean) => void;
    setLoginFailed: (b: boolean) => void;
    setLoading: (B: boolean) => void;
    history: H.History;
    from: H.Location;
}) {
    document.getElementById("litheader").className = "poweron";
    document.getElementById("go").className = "";

    disableInputFields(true);
    const usernameTextField: HTMLInputElement = document.querySelector("input[name=\"user\"]");
    const username = usernameTextField.value;
    const passwordTextField: HTMLInputElement = document.querySelector("input[name=\"password\"]");
    const password = passwordTextField.value;
    setLoading(true);
    authenticator.login(username, password)
        .then((user: User) => {
            if (user) {
                userStore.set({username: user.username, cognitoUserName: user.cognitoUserName, idToken: user.idToken, sessionStarted: user.sessionStarted});
                window.localStorage.setItem("USER", JSON.stringify(user));
                setAuthenticated(true);
                setLoading(false);
            }
        }).catch((reason: string | CognitoException) => {
        if (reason as CognitoException) {
            const ex = reason as CognitoException;
            console.log("Message from CognitoException: " + ex.message)
        } else {
            console.log("Got error: " + reason)
        }
        //   this.setState({loginFailed: true});
        setLoginFailed(true);
    }).finally(() => {
        disableInputFields(false);
        setLoading(false);
    });
}
