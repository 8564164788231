import User = require("../../../model/user");
import Client = require("../../../client/client");
import * as React from "react";
import Portfolio = require("../../../model/portfolio");

interface HoldingProps {
    user: User
    portfolio: Portfolio
}

interface HoldingState {
    statusCode: number
    jsonResponse: any
    loading: boolean
}

export class HoldingsTable extends React.Component<HoldingProps, HoldingState> {
    private client: Client = null;

    constructor(props: HoldingProps, context: any) {
        super(props, context);
        this.client = new Client();
        this.state = {loading: true, statusCode: 0, jsonResponse: null}
    }

    public componentDidMount(): void {
        this.client.fetchHoldings(this.props.portfolio, this.props.user.idToken)
            .then(rsp => {
                if (rsp.status === 200) {
                    rsp.json().then(json => {
                        this.setState({statusCode: rsp.status, jsonResponse: json, loading: false});
                    });
                } else if (rsp.status === 204) {
                    this.setState({statusCode: rsp.status, jsonResponse: null, loading: false});
                } else {
                    this.setState({statusCode: rsp.status, jsonResponse: null, loading: false});
                }
            }).catch(reason => {
            this.setState({statusCode: 400, jsonResponse: null, loading: false});
        });
    }

    public render() {
        if (this.state.loading) {
            return (<img src="../img/loading.gif" className="loading-img" style={{width: "10%"}}/>);
        }

        return (
            <div>
                <h2 className="stocks-page-headline">Innehav kapitalförsäkring</h2>
                <table className="stock-table">
                    <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Land</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        )
    }
}
