import * as React from "react";
import {UserManager} from "../managers/usermanager";
import userStore = require("../store/userstore");

function logout() {
    window.localStorage.removeItem("USER");
    userStore.clear(true);
}

export default function Menu() {

    let logoutLink: JSX.Element = null;

    if (UserManager.isAuthenticated()) {
        logoutLink = <li onClick={logout}><a href="#logout">Logga ut {UserManager.get().username}</a></li>;
    }

    return (
        <div className="mainMenu">
            <ul>
                <li><a href="/">Hem</a></li>
                <li><a href="/about">Om</a></li>
                {logoutLink}
            </ul>
        </div>
    )
}
