module StringUtil {
    export function  requireNotEmpty(str: string, errMessage?: string) {
        if (str.length === 0) {
            throw new RangeError(errMessage)
        }
        return str;
    }
}

export = StringUtil;
