import Auth from "@aws-amplify/auth"
import {requireNotEmpty} from "../util/string/stringutil";
import User = require("../model/user");
import CognitoException = require("../exceptions/cognitoexception");

class Authenticator {

    public async login(username: string, password: string): Promise<User | CognitoException> {
        return new Promise<User>(function(resolve, reject) {
            try {
                const user = requireNotEmpty(username, "username must not be empty");
                const passwd = requireNotEmpty(password, "password must not be empty");
                Auth.signIn(
                    user,
                    passwd,
                    null)
                    .then(cognitoUser => {
                    resolve({username: username, cognitoUserName: cognitoUser.getUsername(), idToken: cognitoUser.getSignInUserSession().getIdToken().getJwtToken(), sessionStarted: new Date().getTime()});
                }).catch(reason => {
                    reject({code: reason.code, name: reason.name, message: reason.message});
                });
            } catch (e) {
                if (e instanceof RangeError) {
                    reject(e)
                } else {
                    reject(e)
                }
            }
        });
    }
}

export = Authenticator;
