import * as React from "react";
import { withRouter, Redirect } from "react-router-dom";
import {RouteComponentProps} from "react-router";

type Props =  { id: string };

class About extends React.Component<RouteComponentProps<Props>> {

    constructor(props: RouteComponentProps<Props>, context?: any) {
        super(props, context);
    }

    public render() {
        return (
            <div>
                <div className="about">
                    <h2>Switch Case Börs</h2>
                    <p>De mest köpvärda aktierna utifrån olika strategier.</p>
                </div>
            </div>
        )
    }
}

export default withRouter(About)
